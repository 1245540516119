import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Dex Asian',
  description:
    'The most popular AMM on BSC by user count! Earn DEX through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Dex Asian), NFTs, and more, on a platform you can trust.',
  image: 'https://dexasian.finance/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Dex Asian')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Dex Asian')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Dex Asian')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Dex Asian')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Dex Asian')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Dex Asian')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Dex Asian')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Dex Asian')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Dex Asian')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Dex Asian')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Dex Asian')}`,
      }
    default:
      return null
  }
}
